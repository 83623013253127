import React from "react";
import { graphql } from "gatsby";
import { renderRichText } from "gatsby-source-contentful/rich-text";
import { Layout } from "~/templates/Layout";
import { PageHeading } from "~/atoms/PageHeading";
import { Options } from "~/molecules/BaseArticleBody";
import { CallToActionButton } from "~/atoms/CallToActionButton";
import { colors } from "~/utils/colors";

interface Props {
  data: {
    contentfulThanks: GatsbyTypes.ContentfulThanks;
  };
  pageContext: {
    contentfulId: string;
  };
}

const Thanks: React.FC<Props> = ({ data }) => {
  const { content, title } = data.contentfulThanks;

  return (
    <>
      <style jsx>{`
        .thanks {
          align-items: center;
          background: ${colors.bgGrayGradient};
          display: flex;
          flex-direction: column;
          padding: 0 0 50px;
        }
        .content {
          position: relative;
          margin: 0 0 30px;
          text-align: center;
        }
        .content :global(p) {
          line-height: 1.8;
        }
        .content :global(h2) {
          border-left: none;
          color: ${colors.defaultText};
        }
        .content :global(p) {
          font-size: 15px;
          margin: 20px;
        }
      `}</style>
      <Layout title={title || ``}>
        <div className="thanks">
          {title && <PageHeading title={title} />}
          <div className="content">{content && renderRichText(content, Options)}</div>
          <div className="buttonWrapper">
            <CallToActionButton to="/" size={`250px`} backgroundColor={colors.creamGray}>
              トップページへ戻る
            </CallToActionButton>
          </div>
        </div>
      </Layout>
    </>
  );
};

export default Thanks;

export const thanksQuery = graphql`
  query Thanks($contentfulId: String!) {
    contentfulThanks(contentful_id: { eq: $contentfulId }) {
      title
      content {
        raw
      }
    }
  }
`;
